<template v-if="dsContractInfo.current">
    <div style="background-color:#F5F5F5" class="p-2 border rounded">
        <div class="mb-1">
            <div class="row ">
                <div class="d-flex justify-content-start">
                    <h6 class="mb-0"><a target="_blank" :href="`/nt/contracts/contract?ID=${dsContractInfo.current.ID}`">PO: {{ dsContractInfo.current.Name }}</a></h6>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <label class="form-label"> {{ $t("Title") }} </label>
                    <input type="text" class="form-control form-control-sm" v-model="dsContractInfo.current.Title" readonly :disabled="true">
                </div>
                
                <div class="col-8">
                    <label class="form-label"> {{ $t("Description") }} </label>
                    <input type="text" class="form-control form-control-sm" v-model="dsContractInfo.current.Description" readonly :disabled="true">
                </div>
            </div>
            <div class="row ">                    
                <div class="col-2">
                    <label class="form-label"> {{ $t("Committed") }} </label>
                    <input type="text" class="form-control form-control-sm text-end" format="1 234" v-model="formattedCommitted" readonly :disabled="true">
                </div>
                
                <div class="col-2">
                    <label class="form-label"> {{ $t("Booked") }} </label>
                    <input type="text" class="form-control form-control-sm text-end" format="1 234" v-model="formattedBooked" readonly :disabled="true">
                </div>
                
                <div class="col-2" :disabled="true">
                    <label class="form-label"> {{ $t("Remaining") }} </label>
                    <input type="text" class="form-control form-control-sm text-end" format="1 234" v-model="formattedRemaining" readonly :disabled="true">
                </div>
            </div>
                        
            <h4 class="accordion-header mb-3 mt-2" id="headingOne">               
                <label class="button" type="button" @click="togglePODetails">
                    <i :class="showPODetails ? 'bi-chevron-down' : 'bi-chevron-right'"></i>
                    {{ showPODetails ? $t("Hide PO Line Items") : $t("View PO Line Items") }}
                </label>
            </h4>
            <div v-if="showPODetails">
                <div class="accordion-body row pt-0">
                    <ODataGrid :data-object="dsContractInfoItems" hideMultiselectColumn hideGridMenu disableFilterRow style="max-height:400px; min-height:100px"
                        disableDeleteConfirm newRecordsPosition="bottom" noActiveRows>
                        <OColumn field="Name" headerName="Element No" width="150" sortable :editable="false"></OColumn>
                        <OColumn field="ItemNo" width="100" :editable="false"> </OColumn>
                        <OColumn field="Description" width="240" :editable="false"> </OColumn>
                        <OColumn field="Quantity" class="text-end" :editable="false" width="80">
                            <template #default="{row}">
                                <span>{{ utils.formatNumber(row.Quantity, '1 234') }}</span>
                            </template>                        
                        </OColumn>         
                        <OColumn field="Unit" width="60" :editable="false"></OColumn>       
                        <OColumn field="Rate" class="text-end" format="1 234" width="80" :editable="false">
                            <template #default="{row}">
                                <span>{{ utils.formatNumber(row.Rate, '1 234') }}</span>
                            </template>                        
                        </OColumn>
                        <OColumn field="Amount" class="text-end" width="100" format="1 234.12" :editable="false">
                            <template #default="{row}">
                                <span>{{ utils.formatNumber(row.Amount, '1 234') }}</span>
                            </template>                        
                        </OColumn>
                    </ODataGrid>    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { getOrCreateProcedure } from 'o365-modules';
    import { defineProps } from 'vue';
    import { $t, utils } from 'o365-utils';
    
    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean
    });

    const formattedCommitted = computed((type) => {
        return dsContractInfo.current ? utils.formatNumber(dsContractInfo.current.Committed, '1 234') : '';
    });

    const formattedBooked = computed(() => {
        return dsContractInfo.current ? utils.formatNumber(dsContractInfo.current.Booked, '1 234') : '';
    });

    const formattedRemaining = computed(() => {
        return dsContractInfo.current ? utils.formatNumber(dsContractInfo.current.Remaining, '1 234') : '';
    });

    const showPODetails = ref(false);
    
    function togglePODetails() {
        showPODetails.value = !showPODetails.value;
    }

    const procGetPOID = getOrCreateProcedure({ id:"procGetPOID", procedureName:"astp_MSFT_MPAP_GetPOID" });

    procGetPOID.execute({Item_ID: props.itemId }).then((pData)=>{
        dsContractInfo.recordSource.whereClause = "ID = " + pData.Table[0].OrgUnit_ID;
        dsContractInfo.load();
    }).catch((pErr)=>{
        
    });

    const dsContractInfo = getOrCreateDataObject(
    {
        "id": "dsContractInfo",
        "viewName": "aviw_MSFT_MPAP_POInfo",
        "distinctRows": false,
        "distinctRows": false,
        "allowUpdate":  false,
        "allowInsert":  false,
        "allowDelete":  false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "ID"},
            {"name": "Name"},
            {"name": "Title"},
            {"name": "Description"},
            {"name": "Committed"},
            {"name": "Booked"},      
            {"name": "Remaining"},         
        ],
        "clientSideHandler": false,
        "maxRecords": 1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    const dsContractInfoItems = getOrCreateDataObject({
        "id": "dsContractInfoItems",
        "viewName": "aviw_MSFT_MPAP_POInfoItems",
        "distinctRows": false,
        "allowUpdate":  false,
        "allowInsert":  false,
        "allowDelete":  false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "ID"},
            {"name": "OrgUnit_ID"},
            {"name": "Name"},
            {"name": "Element_ID"},
            {"name": "ItemNo"},
            {"name": "Description"},
            {"name": "Quantity"},
            {"name": "Unit"},
            {"name": "Rate"},
            {"name": "Amount"},
            {"name": "Issued"},            
        ],
        "masterDataObject_ID": "dsContractInfo",
        "masterDetailDefinition": [
            {
                "detailField": "OrgUnit_ID",
                "operator": "equals",
                "masterField": "ID"
            }
        ],
        "clientSideHandler": false,
        "maxRecords": 50,
        "dynamicLoading": false,
        "whereClause": "",
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    var vTimeout = null;
    dsContractInfo.on("FieldChanged", ()=>{
        if(vTimeout){
            clearTimeout(vTimeout);
        }
        vTimeout = setTimeout(()=>{
            dsContractInfo.save();
        },400)

    });

    dsContractInfo.on("DataLoaded",()=>{
        dsContractInfoItems.allowInsert = false;
    });
</script>